import React from "react"
import { useIntl, Link } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import ThreeUp from "../components/sections/ThreeUp/ThreeUp"
import Notification from "../components/hoc/notification/notification"

const About = () => {
  const i18n = useIntl().formatMessage

  return (
    <>
      <SEO title="About" />
      <section className="section-padding-big bg-lightblue">
        <div className="container">
            <div className="row center-xs middle-xs">
                <div className="col col-xs-12 col-md-8 col-lg-6 space-xs-up">
                    <Notification />
                </div>
            </div>
          <div className="row text-center center-xs text-center">
            <div className="col col-xs-12 col-md-6">
              <h1>Worksiter elsker iværksættere</h1>
              <p>Vi er selv iværksættere der elsker og nyder godt af alle de services, der hjælper os med at fokusere på det, vi er bedst til. Derfor har vi skabt Worksiter, for at hjælpe andre iværksættere med at fokusere på deres eget håndværk.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row middle-xs">
            <div className="col col-xs-12 col-md-8">
              <div className="embed-responsive">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8996.544154819521!2d12.5499567687271!3d55.686622580535726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652530770f0b3cf%3A0x700bf68f8511c9f0!2zQmzDpWfDpXJkc2dhZGUgMTksIDIyMDAgS8O4YmVuaGF2bg!5e0!3m2!1sda!2sdk!4v1600712833815!5m2!1sda!2sdk" width="600" height="450" frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe> 
              </div>
            </div>
            <div className="col col-xs-12 col-md-4">
              <h2>Kontakt os</h2>
              <p>
                MAMA I/S<br/>
                Blågårdsgade 19, kld<br/>
                2200 København N<br/>
                Email: <a href="mailto:info@worksiter.com">info@worksiter.com</a><br/>
                CVR: 34403139
                </p>
            </div>
          </div>
        </div>
      </section>
      <ThreeUp 
                title="Registrér dig og vær online på 5 minutter" 
                elements={
                    [
                    {
                        title: i18n({ id: "pages.index.three_up.texts.1.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.1.text" })
                    },
                    {
                        title: i18n({ id: "pages.index.three_up.texts.2.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.2.text" })
                    },
                    {
                        title: i18n({ id: "pages.index.three_up.texts.3.title" }),
                        text: i18n({ id: "pages.index.three_up.texts.3.text" })
                    }
                    ]
                }
                cta={[{link: "/register", linkTitle: "Prøv det"}]}
                />

    </>
    )
}

export default About
